import {
  Container,
  createMuiTheme,
  CssBaseline,
  Link,
  makeStyles,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import logo from './m800.png';
import './App.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const theme = createMuiTheme({
  palette: {
    type: 'light',
    background: {
      default: '#ffe400',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.64)',
    },
  },
  overrides: {
    MuiLink: {
      root: {
        color: 'rgba(0, 0, 0, 0.64)',
      },
    },
  },
});
function App() {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={logo} className="App-logo" alt="logo" />
          <Typography component="h1" variant="h5">
            Mowhi oy
          </Typography>
          <Typography component="h2" variant="subtitle1">
            Software studio & JavaScript consultancy
          </Typography>
          <p>
            <Link href="mailto:mowhi@mowhi.com">mowhi@mowhi.com</Link> |{' '}
            <Link href="tel:+358400610836">+358 400 610 836</Link> |
            Asuntamaanrinne 1 A 12, 33870 Tampere | business ID: 2286032-7
          </p>
          <p>&copy; 2009–{new Date().getFullYear()}</p>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default App;
